<template>
  <div class="staff height1">
    <!--      头部      -->
    <bread></bread>
    <!-- <div v-else class="iq-top-navbar">
            <el-page-header @back="goBack" content="下级账号">
            </el-page-header>
        </div> -->
    <!--    内容    -->
    <div class="center-content">
      <div class="iq-card-body">
        <div class="table-top">
          <div class="iq-search-bar">
            <el-form :model="searchForm" :inline="true">
              <el-form-item>
                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="测试人/测试人手机号" @keyup.enter.native="search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <el-table :data="UserData" stripe style="width: 100%" ref="multipleTable" height="calc(100% - 90px)" :header-cell-style="{ background: '#F5F7FC' }">
          <!-- <el-table-column type="selection" width="55">
                    </el-table-column> -->
          <el-radio :label="0">6岁以下</el-radio>
          <el-radio :label="1">7-12岁</el-radio>
          <el-radio :label="2">13-17岁</el-radio>
          <el-radio :label="3">18-45岁</el-radio>
          <el-radio :label="4">46-69岁</el-radio>
          <el-radio :label="5">69岁以上</el-radio>
          <el-table-column type="index" label="编号" width="80"></el-table-column>
          <el-table-column prop="answerUserName" label="测试人"> </el-table-column>
          <el-table-column prop="answerPhone" label="测试人手机号" align="center"> </el-table-column>
          <el-table-column prop="answerAge" label="测试人年龄" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.answerAge == 0">6岁以下</p>
              <p v-if="scope.row.answerAge == 1">7-12岁</p>
              <p v-if="scope.row.answerAge == 2">13-17岁</p>
              <p v-if="scope.row.answerAge == 3">18-45岁</p>
              <p v-if="scope.row.answerAge == 4">46-69岁</p>
              <p v-if="scope.row.answerAge == 5">69岁以上</p>
            </template>
          </el-table-column>
          <el-table-column prop="answerSex" label="性别" align="center">
            <template slot-scope="scope">
              <p v-if="scope.row.answerSex">女</p>
              <p v-else>男</p>
            </template>
          </el-table-column>
          <el-table-column prop="diseaseName" label="基础病" align="center">
            <template slot-scope="scope">
              <p>{{ scope.row.diseaseName ? scope.row.diseaseName : '无' }}</p>
            </template>
          </el-table-column>
          <el-table-column prop="userName" label="所属用户" align="center"> </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <span class="primary optionBtn" @click="toDetail(scope.row)">明细</span>
              <!-- <span class="primary optionBtn" @click="toDetail(scope.row)">查看测试记录</span> -->
              <!-- <span class="primary optionBtn" @click="toInquiryTestDetail(scope.row)">辨证论治记录</span> -->
            </template>
          </el-table-column>
        </el-table>
        <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex" :total="StaffTotal" layout="total, prev, pager, next, jumper"></el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import bread from '../../../components/common/breadcrumb'
import { Distributor } from '../../../components/HospitalDomain/Distributor'
import { SelfTest } from '../../../components/TestRecord/SelfTest'
export default {
  name: 'Staff',
  components: {
    bread,
  },
  data() {
    var distributor = new Distributor(this.TokenClient, this.Services.Authorization)
    var selfTest = new SelfTest(this.TokenClient, this.Services.Healthy)
    return {
      DistributorDomain: distributor,
      SelfTestDomain: selfTest,
      searchForm: {
        keyWord: '',
      },
      PageIndex: 1,
      PageTotal: 1,
      PageSize: 15,
      StaffTotal: 0,
      UserData: [],
      userInfo: JSON.parse(sessionStorage.getItem('userInfo')),
      OrgStaffIdList: [],
    }
  },
  mounted() {
    this.OrgStaffIds()
  },
  methods: {
    OrgStaffIds() {
      var _this = this
      _this.DistributorDomain.OrgStaffIds(
        (res) => {
          this.OrgStaffIdList = res.data
          this.InitialUser()
        },
        (error) => {
          console.log('请求失败!请刷新重试:', error)
        }
      )
    },
    toDetail(item) {
      // this.$router.push({
      //   path: '/TestRecord/SelfTest',
      //   query: {
      //     id: item.id,
      //   },
      // })
      this.$router.push({
        path: '/TestRecord/AnswerUserTest',
        query: {
          answerPhone: item.answerPhone,
        },
      })
    },
    toInquiryTestDetail(item) {
      this.$router.push({
        path: '/TestRecord/AnswerUserInquiryTest',
        query: {
          answerPhone: item.answerPhone,
        },
      })
    },
    ChangePage(pageIndex) {
      this.PageIndex = pageIndex
      this.InitialUser()
    },
    search() {
      this.PageIndex = 1
      this.InitialUser()
    },
    InitialUser() {
      var _this = this
      var item = _this.searchForm
      _this.SelfTestDomain.OrgAnswerInfo(
        this.OrgStaffIdList,
        item.keyWord,
        _this.PageIndex,
        function (data) {
          _this.UserData = data.data.results
          _this.PageIndex = data.data.pageIndex
          _this.PageSize = data.data.pageSize
          _this.PageTotal = data.data.pageCount
          _this.StaffTotal = data.data.dataTotal
        },
        function (error) {
          console.log(error)
        }
      )
    },
    goBack() {
      this.$router.go(-1)
    },
  },
}
</script>

<style scoped>
.el-page-header {
  line-height: 73px;
  padding-left: 20px;
}
</style>
